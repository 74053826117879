import React, { useLayoutEffect, useEffect, useContext, useState } from "react"
import Modal from "react-modal"
import gsap from "gsap"
import ReactMarkdown from "react-markdown"
import { MapInteractionCSS } from "react-map-interaction"
import { useMediaPredicate } from "react-media-hook"

import ImgTest from "../assets/img/sample/img-home-whatson-2.png"

import { PopupContext } from "../context/PopupContext"
import { StockContext } from "../context/StockContext"
import { LangContext } from "../context/LangContext"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Container, Section } from "../components/anti/grid/grid"
import { Cover } from "../components/anti/cover/cover"
import { LeadForm } from "../components/anti/lead-form/lead-form"
import { CoreHeading } from "../components/gutenberg/core-heading"
import { CoreCover } from "../components/gutenberg/core-cover"
import { AcfCoverMain } from "../components/gutenberg/acf-cover-main"
import { AcfCoverCard } from "../components/gutenberg/acf-cover-card"
import { AcfHeadline } from "../components/gutenberg/acf-headline"
import { AcfColCoverConterWIconList } from "../components/gutenberg/acf-col-cover-content-w-icon-list"
import { AcfColTextWStatsStatic } from "../components/gutenberg/acf-col-text-w-stats-static"
import { AcfEventSlider } from "../components/gutenberg/acf-event-slider"
import { AcfDualCardSlider } from "../components/gutenberg/acf-dual-card-slider"
import { AcfTextWImgCol } from "../components/gutenberg/acf-text-w-img-col"
import { AcfGallery } from "../components/gutenberg/acf-gallery"
import {
  AcfTextWImgColWoContainer,
  AcfTextWImgColWoContainerAwards,
} from "../components/gutenberg/acf-text-w-image-wo-container"
//prettier-ignore
import { AcfIconList, AcfIconListLink } from "../components/gutenberg/acf-iconlist"
import { AcfListImage } from "../components/gutenberg/acf-list-image"
import { AcfColHeadline } from "../components/gutenberg/acf-col-headline"
import { AcfTextWColCards } from "../components/gutenberg/acf-text-w-cards-col"
import { AcfColTextWStats } from "../components/gutenberg/acf-col-text-w-stats"
import { AcfCoverContent } from "../components/gutenberg/acf-cover-content"
import { AcfExperiment } from "../components/gutenberg/acf-experiment"
import {
  AnggaranReportSection,
  Reports,
  BoardOfDirectors,
  Piagam,
  FilePDF,
  PdfSection,
  PdfSection2,
} from "../components/gutenberg/shortcode-reports"
import {
  ContactUsForm,
  InvestorsRelationsForm,
  IndeksForm,
} from "../templates/form"
import { Button } from "../components/anti/buttons/buttons"
import { StockSectionV2 } from "../components/anti/stock-section"
import { Card } from "../components/anti/card/card"

import { detectLanguage } from "../utils/detect-language"
import { replaceUnicode, Link } from "../components/anti/utils/utils"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const PageTemplate = ({ pageContext, location }) => {
  const { popupBanner, setPopup } = useContext(PopupContext)
  const { stock, setStock } = useContext(StockContext)
  const { lang, setLang } = useContext(LangContext)

  const [board, setBoard] = useState("board-of-commissioners")
  const [allAwardsOffset, setAllAwardsOffset] = useState(1)
  const handleBoard = arg => {
    setBoard(arg)
  }
  const [modal, setModal] = useState(false)
  const [homeSchema, setHomeSchema] = useState()
  const resizeScreen = useMediaPredicate("(max-width: 992px)")
  let homeSchemaVar
  let arrAwards = []
  let awardsObject = {}
  let plcBannerSrc = ""
  const { seo } =
    pageContext.langKey === "en"
      ? pageContext.data
      : pageContext.data.translation || pageContext.data

  const wp = pageContext?.data?.blocks
  const wpID =
    pageContext?.data?.translation && pageContext?.data?.translation.blocks

  const SEOTitle =
    lang === "EN"
      ? pageContext?.data?.title
      : pageContext?.data?.translation?.title || pageContext?.data?.title

  const popup =
    pageContext.langKey === "en"
      ? pageContext?.data?.pop_up
      : pageContext?.data?.translation?.pop_up || pageContext?.data?.pop_up

  const facilities =
    pageContext?.facilities &&
    pageContext?.facilities?.wordPress &&
    pageContext?.facilities?.wordPress?.facility_categories?.nodes
  const reports = pageContext?.report

  const facilitiesTranslation =
    lang === "EN"
      ? pageContext?.data?.childPages?.nodes
      : pageContext?.data?.translation?.childPages?.nodes ||
        pageContext?.data?.childPages?.nodes

  const ourPartners =
    pageContext?.ourPartners &&
    pageContext?.ourPartners?.wordPress &&
    pageContext?.ourPartners?.wordPress?.ourPartners?.nodes

  const embedCompanyLocation =
    pageContext?.data &&
    pageContext?.data?.embedCompanyLocation &&
    pageContext?.data?.embedCompanyLocation?.embedUrl

  const placementBanner =
    pageContext?.data && pageContext?.data?.placementBanner

  if (!resizeScreen) {
    plcBannerSrc = placementBanner?.desktopBanner?.sourceUrl
      ? placementBanner?.desktopBanner?.sourceUrl
      : "#"
  } else {
    plcBannerSrc = placementBanner?.mobileBanner?.sourceUrl
      ? placementBanner?.mobileBanner?.sourceUrl
      : "#"
  }

  const Pagination = ({ hasPreviousPage, hasNextPage, prevPage, nextPage }) => {
    return (
      <div className="d-flex justify-content-end">
        <div className="paginate">
          <button
            className="btn btn-primary pagination-button"
            disabled={hasPreviousPage}
            onClick={prevPage}
          >
            <i className="fas fa-chevron-left" />
          </button>
          <button
            className="btn btn-primary pagination-button"
            disabled={hasNextPage}
            onClick={nextPage}
          >
            <i className="fas fa-chevron-right" />
          </button>
        </div>
      </div>
    )
  }

  const allAwardsNextPage = () => {
    if (arrAwards[allAwardsOffset * 10] !== undefined) {
      setAllAwardsOffset(allAwardsOffset + 1)
    }
  }

  const allAwardsPrevPage = () => {
    if (allAwardsOffset >= 1) {
      setAllAwardsOffset(allAwardsOffset - 1)
    }
  }

  useEffect(() => {
    if (location.pathname === "/") {
      homeSchemaVar = [
        {
          "@context": "https://schema.org",
          "@type": "WebSite",
          url: "https://www.bsdcity.com",
          potentialAction: {
            "@type": "SearchAction",
            target: `https://www.bsdcity.com/search-result?key=${
              sessionStorage.getItem("search_key")
                ? sessionStorage.getItem("search_key")
                : ""
            }`,
            "query-input": "required name=search_term_string",
          },
        },
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          name: "Bsdicity.com",
          url: "https://www.bsdcity.com",
          logo:
            "https://www.bsdcity.com/static/logo_bsd-dark-2494e17d28a8cb4bf752adb24904fa4b.png",
          sameAs: [
            "https://www.facebook.com/bsdcity01",
            "https://twitter.com/bsdcity_?lang=en",
            "https://www.instagram.com/bsdcity_official/?hl=en",
            "https://www.youtube.com/user/sinarmasland",
            "https://www.tiktok.com/@sinarmas_land",
          ],
        },
      ]

      setHomeSchema(homeSchemaVar)
    }
  }, [])

  useEffect(() => {
    detectLanguage(location.pathname, setLang)
  }, [])

  useLayoutEffect(() => {
    if (
      (wp && wp[0].name !== "acf/cover-main") ||
      pageContext.data.slug === "annual-financial-reports" ||
      pageContext.data.slug === "general-information" ||
      pageContext.data.slug === "corporate-governance" ||
      pageContext.data.slug === "press-release" ||
      pageContext.data.slug === "board-of-directors" ||
      pageContext.data.slug === "information-disclosure"
    ) {
      const navbar = document.querySelector(".navbar")
      navbar.classList.remove("transparent")
      navbar.classList.replace("dark", "light")
    }
  }, [])

  useEffect(() => {
    if (popupBanner && !sessionStorage.getItem("popup")) {
      setTimeout(() => {
        setModal(true)
      }, 2000)
    }

    if (location?.pathname !== "/indeks/") {
      let stockBSDE
      let stockDUTI
      let stockA26

      if (stock.bsde === 0 && stock.duti === 0 && stock.a26 === 0) {
        fetch(
          "https://yahoo-finance15.p.rapidapi.com/api/yahoo/hi/history/BSDE.JK/1d",
          {
            method: "GET",
            headers: {
              "x-rapidapi-host": "yahoo-finance15.p.rapidapi.com",
              "x-rapidapi-key": process.env.YAHOO_API_KEY,
              useQueryString: true,
            },
          }
        )
          .then(response => response.json())
          .then(responseJson => responseJson && (stockBSDE = responseJson))
          .then(() => {
            fetch(
              "https://yahoo-finance15.p.rapidapi.com/api/yahoo/hi/history/DUTI.JK/1d",
              {
                method: "GET",
                headers: {
                  "x-rapidapi-host": "yahoo-finance15.p.rapidapi.com",
                  "x-rapidapi-key": process.env.YAHOO_API_KEY,
                  useQueryString: true,
                },
              }
            )
              .then(duti => duti.json())
              .then(dutiJson => dutiJson && (stockDUTI = dutiJson))
              .then(() => {
                fetch(
                  "https://yahoo-finance15.p.rapidapi.com/api/yahoo/hi/history/A26.SI/1d",
                  {
                    method: "GET",
                    headers: {
                      "x-rapidapi-host": "yahoo-finance15.p.rapidapi.com",
                      "x-rapidapi-key": process.env.YAHOO_API_KEY,
                      useQueryString: true,
                    },
                  }
                )
                  .then(a26 => a26.json())
                  .then(a26Json => a26Json && (stockA26 = a26Json))
                  .then(() => {
                    if (stockBSDE && stockDUTI && stockA26) {
                      setStock({
                        bsde: stockBSDE,
                        duti: stockDUTI,
                        a26: stockA26,
                      })
                    }
                  })
                  .catch(err2 => err2)
              })
              .catch(err1 => err1)
          })
          .catch(err => err)
      }
    }
    // prettier-ignore
    gsap.timeline()
      // .to(".bg-loader-wrapper", 1, {autoAlpha: 0, ease: "expo.inOut", })
      // .from(".cover", 1, {autoAlpha: 0, ease: "expo.inOut", })
      .from(".cover-label", 2, { y: 100, skewY: 7, ease: "expo.inOut", delay: -1, })
      .from(".cover-title", 2, { y: 100, skewY: 7, ease: "expo.inOut", delay: -1.8, })
      .to(".cover-bg", 2, { scale: 1, ease: "expo.inOut", delay: -2, })
  }, [])

  useEffect(() => {
    if (!resizeScreen) {
      plcBannerSrc = placementBanner?.desktopBanner?.sourceUrl
        ? placementBanner?.desktopBanner?.sourceUrl
        : "#"
    } else {
      plcBannerSrc = placementBanner?.mobileBanner?.sourceUrl
        ? placementBanner?.mobileBanner?.sourceUrl
        : "#"
    }
  }, [])

  useEffect(() => {
    if (location?.pathname === "/indeks/") {
      const navbar = document.querySelector(".navbar-expand-lg")
      navbar.classList.add("is-scrolled")
    }
  }, [])

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  }

  const handleModal = () => {
    setModal(!modal)
    setPopup(false)
  }

  const Shortcode = ({ data }) => {
    if (data.attributes.text === "annual-report") {
      let reportData = []
      reports &&
        reports.map(
          item =>
            item.file_categories.nodes[0].slug === "annual-reports" &&
            reportData.push(item)
        )
      return (
        <Reports
          title={lang == "EN" ? "Annual Reports" : "Laporan Tahunan"}
          data={reportData.length > 0 && reportData}
          attributes={data.attributes.text}
          className={data.attributes.text}
        />
      )
    } else if (data.attributes.text === "sustainabilty-reports") {
      let reportData = []
      reports &&
        reports.map(
          item =>
            item.file_categories.nodes[0].slug === "sustainability" &&
            reportData.push(item)
        )
      return (
        <Reports
          attributes={data.attributes.text}
          title={
            lang == "EN" ? "Sustainability Reports" : "Laporan Keberlanjutan"
          }
          data={reportData.length > 0 && reportData}
          className={data.attributes.text}
        />
      )
    } else if (
      data.attributes.text === "annual-report-details" ||
      data.attributes.text === "financial-report-details"
    ) {
      return <FilePDF data={reports} slug={pageContext.data.slug} />
    } else if (data.attributes.text === "press-release-report") {
      return <PdfSection2 data={reports} id="press-release" />
    } else if (data.attributes.text === "board-of-directors-report") {
      return <BoardOfDirectors data={reports} slug={pageContext.data.slug} />
    } else if (data.attributes.text === "information-disclosure-report") {
      return <PdfSection2 data={reports} id="information-disclosure" />
    } else if (data.attributes.text === "stock") {
      return (
        <StockSectionV2
          lang={lang}
          stockPrice={
            stock.bsde !== 0 && stock.duti !== 0 && stock.a26 !== 0 && stock
          }
        />
      )
    } else if (data.attributes.text === "anggaran") {
      return (
        <AnggaranReportSection
          data={reports}
          title="Anggaran Dasar Perseroan"
        />
      )
    } else if (data.attributes.text === "piagam") {
      return <Piagam data={reports} title="Piagam" />
    }
    // else if (data.attributes.text === "rups") {
    //   return <PdfSection title="RUPS" id="rups" data={reports} />
    // }
    // else if (data.attributes.text === "rupslb") {
    //   return (<PdfSection title="RUPSLB" id="rupslb" data={reports} />)
    // }
    // else if (data.attributes.text === "rupst") {
    //   return <PdfSection title="RUPST" id="rupst" data={reports} />
    // }
    else if (data.attributes.text === "rups-rupst") {
      return <PdfSection title="RUPS" id="rups-rupst" data={reports} />
    } else if (data.attributes.text === "contact-us-form") {
      return <ContactUsForm />
    } else if (data.attributes.text === "investors-relations-form") {
      return <InvestorsRelationsForm />
    } else if (data.attributes.text === "pay-bill") {
      return (
        <Section className="text-center">
          <Container>
            <p>
              {lang == "EN"
                ? "Pay Water and IPL Bill easily by entering your customer number"
                : "Bayar Air dan Tagihan IPL dengan mudah dengan memasukkan nomor pelanggan Anda"}
            </p>
            <Button
              variant="secondary"
              link="http://airipl.bsdcity.com/login.php"
              target="#"
              className="btn-x-auto-250px mt-4 mb-3"
            >
              {lang == "EN" ? "Pay Bill" : "Bayar Tagihan"}{" "}
              <i className="fas fa-arrow-right ml-1"></i>
            </Button>
            <span className="text-gray-400">
              {lang == "EN"
                ? "You will be directed to another site"
                : "Anda akan diarahkan ke situs lain"}
            </span>
          </Container>
        </Section>
      )
    } else if (data.attributes.text === "facilities") {
      return (
        <Section className="bg-light">
          <Container className="mw-lg">
            <div className="row">
              {facilitiesTranslation !== undefined &&
                facilitiesTranslation.map((item, i) => {
                  if (lang == "EN") {
                    let dataFacilities =
                      facilities && facilities.find(x => x.slug === item.slug)
                    if (dataFacilities !== undefined) {
                      return (
                        <div
                          className="col-6 col-md-3 mb-3"
                          key={`${item.title}${i}`}
                        >
                          <Card
                            className="card-facilities-category"
                            cardBodyClassName="pt-5 px-0"
                            colLeft="col-12"
                            colRight="col-12"
                            icon={
                              dataFacilities.acfFacilityCategory !== undefined
                                ? dataFacilities.acfFacilityCategory.icon
                                    .sourceUrl
                                : ""
                            }
                            title={`${dataFacilities !== undefined &&
                              dataFacilities.count}`}
                            titleClassName="text-center"
                            text={replaceUnicode(item.title)}
                            textClassName="text-center"
                            img={
                              item.featuredImage
                                ? item.featuredImage.sourceUrl
                                : "https://beryl-ks79.antikode.dev/app/uploads/2020/08/img-thumbnail-wellness-1024x570.jpg"
                            }
                            imgHeight="h-ratio-3-2"
                            link={`/facilities/${item.slug}`}
                          />
                        </div>
                      )
                    }
                  } else if (lang == "ID" && item.translation !== null) {
                    let dataFacilities =
                      facilities &&
                      facilities.find(x => x.translation?.slug === item.slug)
                    if (dataFacilities !== undefined) {
                      return (
                        <div
                          className="col-6 col-md-3 mb-3"
                          key={`${item?.title}${i}`}
                        >
                          <Card
                            className="card-facilities-category"
                            cardBodyClassName="pt-5 px-0"
                            colLeft="col-12"
                            colRight="col-12"
                            icon={
                              dataFacilities.acfFacilityCategory !== undefined
                                ? dataFacilities.acfFacilityCategory.icon
                                    .sourceUrl
                                : ""
                            }
                            title={`${dataFacilities !== undefined &&
                              dataFacilities.count}`}
                            titleClassName="text-center"
                            text={item?.title && replaceUnicode(item?.title)}
                            textClassName="text-center"
                            img={
                              item.featuredImage
                                ? item.featuredImage.sourceUrl
                                : "https://beryl-ks79.antikode.dev/app/uploads/2020/08/img-thumbnail-wellness-1024x570.jpg"
                            }
                            imgHeight="h-ratio-3-2"
                            link={`/id/facilities/${dataFacilities.slug}`}
                          />
                        </div>
                      )
                    }
                  }
                })}
            </div>
          </Container>
        </Section>
      )
    } else if (data.attributes.text === "board-tabs") {
      return (
        <Section className="pb-0">
          <Container>
            <div className="board-tabs">
              <ul>
                <li
                  className={board == "board-of-commissioners" && "active"}
                  onClick={() => handleBoard("board-of-commissioners")}
                >
                  <div>
                    {lang == "EN" ? "Commissioners" : "Dewan Komisaris"}
                  </div>
                </li>
                <li
                  className={board == "board-of-directors" && "active"}
                  onClick={() => handleBoard("board-of-directors")}
                >
                  <div>{lang == "EN" ? "Directors" : "Direksi"}</div>
                </li>
              </ul>
            </div>
          </Container>
        </Section>
      )
    } else if (data.attributes.text === "lead-form") {
      return <LeadForm name={pageContext.data.slug} />
    } else if (data.attributes.text === "our-partners") {
      return (
        <Section className="bg-light">
          <Container className="mw-lg">
            <div className="row">
              {ourPartners !== undefined &&
                ourPartners.map((item, i) => {
                  if (lang == "EN") {
                    if (item !== undefined) {
                      return (
                        <div
                          className="col-6 col-md-3 mb-3"
                          key={`${item.title}${i}`}
                        >
                          <Card
                            className="card-facilities-category"
                            cardBodyClassName="pt-5 px-0"
                            colLeft="col-12"
                            colRight="col-12"
                            icon={""}
                            title={`${item !== undefined && item.title}`}
                            titleClassName="text-center"
                            textClassName="text-center"
                            img={
                              item?.featuredImage
                                ? item?.featuredImage?.sourceUrl
                                : "https://beryl-ks79.antikode.dev/app/uploads/2020/08/img-thumbnail-wellness-1024x570.jpg"
                            }
                            imgHeight="h-ratio-3-2"
                            link={`/our-partners/${item.slug}`}
                          />
                        </div>
                      )
                    }
                  } else if (lang == "ID" && item.translation !== null) {
                    if (item !== undefined) {
                      return (
                        <div
                          className="col-6 col-md-3 mb-3"
                          key={`${item.translation.title}${i}`}
                        >
                          <Card
                            className="card-facilities-category"
                            cardBodyClassName="pt-5 px-0"
                            colLeft="col-12"
                            colRight="col-12"
                            icon={""}
                            title={`${item !== undefined &&
                              item.translation?.title}`}
                            titleClassName="text-center"
                            textClassName="text-center"
                            img={
                              item?.translation?.featuredImage
                                ? item?.translation?.featuredImage?.sourceUrl
                                : "https://beryl-ks79.antikode.dev/app/uploads/2020/08/img-thumbnail-wellness-1024x570.jpg"
                            }
                            imgHeight="h-ratio-3-2"
                            link={`/id/our-partners/${item.slug}`}
                          />
                        </div>
                      )
                    }
                  }
                })}
            </div>
          </Container>
        </Section>
      )
    } else if (data.attributes.text === "placement-banner") {
      return (
        <Section className="bg-light">
          <Container>
            <Link
              className="link-plc-banner"
              to={placementBanner?.url ? placementBanner?.url?.url : "/"}
              target={
                placementBanner?.url
                  ? placementBanner?.url?.target
                  : "_self" || "_blank"
              }
            >
              <img
                className="plc-banner-wrapper"
                alt="placement-banner"
                src={plcBannerSrc}
              />
            </Link>
          </Container>
        </Section>
      )
    } else if (data.attributes.text === "indeks-form") {
      return <IndeksForm />
    } else {
      return null
    }
  }

  const SEODefaultImg =
    lang === "EN"
      ? pageContext?.data?.blocks[0]?.coverMain?.img?.mediaItemUrl
      : pageContext?.data?.translation?.blocks[0]?.coverMain?.img
          ?.mediaItemUrl ||
        pageContext?.data?.blocks[0]?.coverMain?.img?.mediaItemUrl

  return (
    <Layout>
      <SEO
        title={seo.title}
        desc={seo.metaDesc}
        fbImg={seo.opengraphImage?.sourceUrl || SEODefaultImg}
        fbTitle={seo.opengraphTitle}
        fbDesc={seo.opengraphDescription}
        url={location.href}
        twitterTitle={seo.twitterTitle}
        twitterDesc={seo.twitterDescription}
        twitterImg={seo.twitterImage?.sourceUrl || SEODefaultImg}
        schemaMarkup={location.pathname === "/" ? homeSchema : null}
      />
      {lang == "ID" &&
        wpID &&
        wpID.map((block, i) => {
          if (pageContext.data.slug === "awards") {
            if (block.name === "acf/col-text-w-image-wo-container") {
              awardsObject = { ...awardsObject, block }
              arrAwards.push(awardsObject)
            }
          }
          switch (block.name) {
            case "core/cover":
              return <CoreCover data={block} key={`${block.name}${i}`} />
            case "core/embed":
              return (
                <div className="mt-10">
                  <Cover
                    type={{ name: "core-embed/youtube" }}
                    theme="dark"
                    videoSrc={block.attributes.url}
                    imgOverlay="30"
                    imgHeight="h-500px h-md-600px w-100"
                    className="cover-full cover-main"
                  />
                </div>
              )
            case "acf/cover-main":
              return (
                <AcfCoverMain
                  className={
                    pageContext.data.slug === "annual-financial-reports" ||
                    pageContext.data.slug === "general-information" ||
                    pageContext.data.slug === "corporate-governance" ||
                    pageContext.data.slug === "press-release" ||
                    pageContext.data.slug === "board-of-directors" ||
                    pageContext.data.slug === "information-disclosure"
                      ? ""
                      : "cover-full"
                  }
                  data={block}
                  // breadcrumb={
                  //   (pageContext.data.slug === "annual-financial-reports" ||
                  //     pageContext.data.slug === "general-information" ||
                  //     pageContext.data.slug === "corporate-governance" ||
                  //     pageContext.data.slug === "press-release" ||
                  //     pageContext.data.slug === "information-disclosure") ||
                  //     pageContext.data.slug === "board-of-directors" ||
                  //     pageContext.data.slug === "investors-relations" &&
                  //   location.pathname
                  // }
                  breadcrumb={
                    pageContext.data.slug === "home"
                      ? ""
                      : pageContext.data.slug === "about"
                      ? ""
                      : pageContext.data.slug === "sustainability"
                      ? ""
                      : pageContext.data.slug === "investor"
                      ? ""
                      : pageContext.data.slug === "facilities"
                      ? ""
                      : pageContext.data.slug === "access"
                      ? ""
                      : pageContext.data.slug === "water-ipl-payment"
                      ? ""
                      : pageContext.data.slug === "our-partners"
                      ? ""
                      : location.pathname
                  }
                  key={`${block.name}${i}`}
                />
              )
            case "acf/headline":
              return <AcfHeadline data={block} key={`${block.name}${i}`} />
            case "acf/col-text-w-image-wo-container":
              return pageContext.data.slug === "awards" ? (
                i > allAwardsOffset * 12 - 12 && i < allAwardsOffset * 12 && (
                  <AcfTextWImgColWoContainerAwards
                    data={awardsObject.block}
                    key={`${block.name}${i}`}
                    slug={pageContext.data.slug}
                  />
                )
              ) : (
                <AcfTextWImgColWoContainer
                  data={block}
                  key={`${block.name}${i}`}
                  slug={pageContext.data.slug}
                />
              )
            case "acf/col-headline":
              return <AcfColHeadline data={block} key={`${block.name}${i}`} />
            case "acf/dual-card-slider":
              return (
                <AcfDualCardSlider data={block} key={`${block.name}${i}`} />
              )
            case "acf/event-slider":
              return <AcfEventSlider data={block} key={`${block.name}${i}`} />
            case "acf/col-cover-content-w-icon-list":
              return (
                <AcfColCoverConterWIconList
                  data={block}
                  key={`${block.name}${i}`}
                />
              )
            case "acf/col-text-w-stats-static":
              return (
                <AcfColTextWStatsStatic
                  data={block}
                  key={`${block.name}${i}`}
                />
              )
            case "acf/col-text-w-image":
              return <AcfTextWImgCol data={block} key={`${block.name}${i}`} />
            case "acf/experiment":
              return (
                <AcfExperiment
                  data={block}
                  embedUrl={embedCompanyLocation}
                  key={`${block.name}${i}`}
                />
              )
            case "acf/acfgallery":
              return (
                <AcfGallery
                  className="py-main"
                  data={block}
                  key={`${block.name}${i}`}
                />
              )
            case "acf/iconlist":
              return location.pathname === "/general-information" ||
                location.pathname === "/investor" ? (
                <AcfIconListLink data={block} key={`${block.name}${i}`} />
              ) : (
                <AcfIconList data={block} key={`${block.name}${i}`} />
              )
            case "acf/list-image":
              return (
                block.attributes.className == board && (
                  <AcfListImage data={block} key={`${block.name}${i}`} />
                )
              )
            case "acf/text-w-col-cards":
              return <AcfTextWColCards data={block} key={`${block.name}${i}`} />
            case "acf/col-text-w-stats":
              return <AcfColTextWStats data={block} key={`${block.name}${i}`} />
            case "acf/cover-content":
              return <AcfCoverContent data={block} key={`${block.name}${i}`} />
            case "acf/cover-card":
              return <AcfCoverCard data={block} key={`${block.name}${i}`} />
            case "core/shortcode":
              return <Shortcode data={block} key={`${block.name}${i}`} />
            case "core/heading":
              return (
                <Container key={`${block.name}${i}`}>
                  <CoreHeading data={block} />
                </Container>
              )
            case "core/paragraph":
              return (
                <Container key={`${block.name}${i}`}>
                  <Section className="py-main-sm text-center">
                    <p>{replaceUnicode(block.attributes.content)}</p>
                  </Section>
                </Container>
              )
            case "core/image":
              return (
                <Container key={`${block.name}${i}`}>
                  <Section className="py-main-sm text-center d-flex justify-content-center">
                    <div className="image-zoom">
                      <div className="overlay"></div>
                      <MapInteractionCSS>
                        <img alt="Structure Image" src={block.attributes.url} />
                      </MapInteractionCSS>
                    </div>
                  </Section>
                </Container>
              )
            default:
              return <div key={`${block.name}${i}`}>Default: {block.name}</div>
          }
        })}

      {lang == "ID" &&
        wpID == null &&
        wp.map((block, i) => {
          if (pageContext.data.slug === "awards") {
            if (block.name === "acf/col-text-w-image-wo-container") {
              awardsObject = { ...awardsObject, block }
              arrAwards.push(awardsObject)
            }
          }
          switch (block.name) {
            case "core/cover":
              return <CoreCover data={block} key={`${block.name}${i}`} />
            case "core/embed":
              return (
                <div className="mt-10">
                  <Cover
                    type={{ name: "core-embed/youtube" }}
                    theme="dark"
                    videoSrc={block.attributes.url}
                    imgOverlay="30"
                    imgHeight="h-500px h-md-600px w-100"
                    className="cover-full cover-main"
                  />
                </div>
              )
            case "acf/cover-main":
              return (
                <AcfCoverMain
                  className={
                    pageContext.data.slug === "annual-financial-reports" ||
                    pageContext.data.slug === "general-information" ||
                    pageContext.data.slug === "corporate-governance" ||
                    pageContext.data.slug === "press-release" ||
                    pageContext.data.slug === "board-of-directors" ||
                    pageContext.data.slug === "information-disclosure"
                      ? ""
                      : "cover-full"
                  }
                  data={block}
                  // breadcrumb={
                  //   (pageContext.data.slug === "annual-financial-reports" ||
                  //     pageContext.data.slug === "general-information" ||
                  //     pageContext.data.slug === "corporate-governance" ||
                  //     pageContext.data.slug === "press-release" ||
                  //     pageContext.data.slug === "information-disclosure") ||
                  //     pageContext.data.slug === "board-of-directors" ||
                  //     pageContext.data.slug === "investors-relations" &&
                  //   location.pathname
                  // }
                  breadcrumb={
                    pageContext.data.slug === "home"
                      ? ""
                      : pageContext.data.slug === "about"
                      ? ""
                      : pageContext.data.slug === "sustainability"
                      ? ""
                      : pageContext.data.slug === "investor"
                      ? ""
                      : pageContext.data.slug === "facilities"
                      ? ""
                      : pageContext.data.slug === "access"
                      ? ""
                      : pageContext.data.slug === "water-ipl-payment"
                      ? ""
                      : pageContext.data.slug === "our-partners"
                      ? ""
                      : location.pathname
                  }
                  key={`${block.name}${i}`}
                />
              )
            case "acf/headline":
              return <AcfHeadline data={block} key={`${block.name}${i}`} />
            case "acf/col-text-w-image-wo-container":
              return pageContext.data.slug === "awards" ? (
                i > allAwardsOffset * 12 - 12 && i < allAwardsOffset * 12 && (
                  <AcfTextWImgColWoContainerAwards
                    data={awardsObject.block}
                    key={`${block.name}${i}`}
                    slug={pageContext.data.slug}
                  />
                )
              ) : (
                <AcfTextWImgColWoContainer
                  data={block}
                  key={`${block.name}${i}`}
                  slug={pageContext.data.slug}
                />
              )
            case "acf/col-headline":
              return <AcfColHeadline data={block} key={`${block.name}${i}`} />
            case "acf/dual-card-slider":
              return (
                <AcfDualCardSlider data={block} key={`${block.name}${i}`} />
              )
            case "acf/event-slider":
              return <AcfEventSlider data={block} key={`${block.name}${i}`} />
            case "acf/col-cover-content-w-icon-list":
              return (
                <AcfColCoverConterWIconList
                  data={block}
                  key={`${block.name}${i}`}
                />
              )
            case "acf/col-text-w-stats-static":
              return (
                <AcfColTextWStatsStatic
                  data={block}
                  key={`${block.name}${i}`}
                />
              )
            case "acf/col-text-w-image":
              return <AcfTextWImgCol data={block} key={`${block.name}${i}`} />
            case "acf/experiment":
              return (
                <AcfExperiment
                  data={block}
                  embedUrl={embedCompanyLocation}
                  key={`${block.name}${i}`}
                />
              )
            case "acf/acfgallery":
              return (
                <AcfGallery
                  className="py-main"
                  data={block}
                  key={`${block.name}${i}`}
                />
              )
            case "acf/iconlist":
              return location.pathname === "/general-information" ||
                location.pathname === "/investor" ? (
                <AcfIconListLink data={block} key={`${block.name}${i}`} />
              ) : (
                <AcfIconList data={block} key={`${block.name}${i}`} />
              )
            case "acf/list-image":
              return (
                block.attributes.className == board && (
                  <AcfListImage data={block} key={`${block.name}${i}`} />
                )
              )
            case "acf/text-w-col-cards":
              return <AcfTextWColCards data={block} key={`${block.name}${i}`} />
            case "acf/col-text-w-stats":
              return <AcfColTextWStats data={block} key={`${block.name}${i}`} />
            case "acf/cover-content":
              return <AcfCoverContent data={block} key={`${block.name}${i}`} />
            case "acf/cover-card":
              return <AcfCoverCard data={block} key={`${block.name}${i}`} />
            case "core/shortcode":
              return <Shortcode data={block} key={`${block.name}${i}`} />
            case "core/heading":
              return (
                <Container key={`${block.name}${i}`}>
                  <CoreHeading data={block} />
                </Container>
              )
            case "core/paragraph":
              return (
                <Container key={`${block.name}${i}`}>
                  <Section className="py-main-sm text-center">
                    <p>{replaceUnicode(block.attributes.content)}</p>
                  </Section>
                </Container>
              )
            case "core/image":
              return (
                <Container key={`${block.name}${i}`}>
                  <Section className="py-main-sm text-center d-flex justify-content-center">
                    <div className="image-zoom">
                      <div className="overlay"></div>
                      <MapInteractionCSS>
                        <img alt="Structure Image" src={block.attributes.url} />
                      </MapInteractionCSS>
                    </div>
                  </Section>
                </Container>
              )
            default:
              return <div key={`${block.name}${i}`}>Default: {block.name}</div>
          }
        })}

      {lang == "EN" &&
        wp &&
        wp.map((block, i) => {
          if (pageContext.data.slug === "awards") {
            if (block.name === "acf/col-text-w-image-wo-container") {
              awardsObject = { ...awardsObject, block }
              arrAwards.push(awardsObject)
            }
          }
          switch (block.name) {
            case "core/cover":
              return <CoreCover data={block} key={`${block.name}${i}`} />
            case "core/embed":
              return (
                <div className="mt-10">
                  <Cover
                    type={{ name: "core-embed/youtube" }}
                    theme="dark"
                    videoSrc={block.attributes.url}
                    imgOverlay="30"
                    imgHeight="h-500px h-md-600px w-100"
                    className="cover-full cover-main"
                  />
                </div>
              )
            case "acf/cover-main":
              return (
                <AcfCoverMain
                  className={
                    pageContext.data.slug === "annual-financial-reports" ||
                    pageContext.data.slug === "general-information" ||
                    pageContext.data.slug === "corporate-governance" ||
                    pageContext.data.slug === "press-release" ||
                    pageContext.data.slug === "board-of-directors" ||
                    pageContext.data.slug === "information-disclosure"
                      ? ""
                      : "cover-full"
                  }
                  data={block}
                  // breadcrumb={
                  //   (pageContext.data.slug === "annual-financial-reports" ||
                  //     pageContext.data.slug === "general-information" ||
                  //     pageContext.data.slug === "corporate-governance" ||
                  //     pageContext.data.slug === "press-release" ||
                  //     pageContext.data.slug === "information-disclosure") ||
                  //     pageContext.data.slug === "board-of-directors" ||
                  //     pageContext.data.slug === "investors-relations" &&
                  //   location.pathname
                  // }
                  breadcrumb={
                    pageContext.data.slug === "home"
                      ? ""
                      : pageContext.data.slug === "about"
                      ? ""
                      : pageContext.data.slug === "sustainability"
                      ? ""
                      : pageContext.data.slug === "investor"
                      ? ""
                      : pageContext.data.slug === "facilities"
                      ? ""
                      : pageContext.data.slug === "access"
                      ? ""
                      : pageContext.data.slug === "water-ipl-payment"
                      ? ""
                      : pageContext.data.slug === "our-partners"
                      ? ""
                      : location.pathname
                  }
                  key={`${block.name}${i}`}
                />
              )
            case "acf/headline":
              return <AcfHeadline data={block} key={`${block.name}${i}`} />
            case "acf/col-text-w-image-wo-container":
              return pageContext.data.slug === "awards" ? (
                i > allAwardsOffset * 12 - 12 && i < allAwardsOffset * 12 && (
                  <AcfTextWImgColWoContainerAwards
                    data={awardsObject.block}
                    key={`${block.name}${i}`}
                    slug={pageContext.data.slug}
                  />
                )
              ) : (
                <AcfTextWImgColWoContainer
                  data={block}
                  key={`${block.name}${i}`}
                  slug={pageContext.data.slug}
                />
              )
            case "acf/col-headline":
              return <AcfColHeadline data={block} key={`${block.name}${i}`} />
            case "acf/dual-card-slider":
              return (
                <AcfDualCardSlider data={block} key={`${block.name}${i}`} />
              )
            case "acf/event-slider":
              return <AcfEventSlider data={block} key={`${block.name}${i}`} />
            case "acf/col-cover-content-w-icon-list":
              return (
                <AcfColCoverConterWIconList
                  data={block}
                  key={`${block.name}${i}`}
                />
              )
            case "acf/col-text-w-stats-static":
              return (
                <AcfColTextWStatsStatic
                  data={block}
                  key={`${block.name}${i}`}
                />
              )
            case "acf/col-text-w-image":
              return <AcfTextWImgCol data={block} key={`${block.name}${i}`} />
            case "acf/experiment":
              return (
                <AcfExperiment
                  data={block}
                  embedUrl={embedCompanyLocation}
                  key={`${block.name}${i}`}
                />
              )
            case "acf/acfgallery":
              return (
                <AcfGallery
                  className="py-main"
                  data={block}
                  key={`${block.name}${i}`}
                />
              )
            case "acf/iconlist":
              return location.pathname === "/general-information" ||
                location.pathname === "/investor" ? (
                <AcfIconListLink data={block} key={`${block.name}${i}`} />
              ) : (
                <AcfIconList data={block} key={`${block.name}${i}`} />
              )
            case "acf/list-image":
              return (
                block.attributes.className == board && (
                  <AcfListImage data={block} key={`${block.name}${i}`} />
                )
              )
            case "acf/text-w-col-cards":
              return <AcfTextWColCards data={block} key={`${block.name}${i}`} />
            case "acf/col-text-w-stats":
              return <AcfColTextWStats data={block} key={`${block.name}${i}`} />
            case "acf/cover-content":
              return <AcfCoverContent data={block} key={`${block.name}${i}`} />
            case "acf/cover-card":
              return <AcfCoverCard data={block} key={`${block.name}${i}`} />
            case "core/shortcode":
              return <Shortcode data={block} key={`${block.name}${i}`} />
            case "core/heading":
              return (
                <Container key={`${block.name}${i}`}>
                  <CoreHeading data={block} />
                </Container>
              )
            case "core/paragraph":
              return (
                <Container key={`${block.name}${i}`}>
                  <Section className="py-main-sm text-center">
                    <p>{replaceUnicode(block.attributes.content)}</p>
                  </Section>
                </Container>
              )
            case "core/image":
              return (
                <Container key={`${block.name}${i}`}>
                  <Section className="py-main-sm text-center d-flex justify-content-center">
                    <div className="image-zoom">
                      <div className="overlay"></div>
                      <MapInteractionCSS>
                        <img alt="Structure Image" src={block.attributes.url} />
                      </MapInteractionCSS>
                    </div>
                  </Section>
                </Container>
              )
            default:
              return <div key={`${block.name}${i}`}>Default: {block.name}</div>
          }
        })}

      {pageContext.data.slug === "awards" && arrAwards.length > 10 && (
        <Container>
          <div className="col-12 mb-5 enpagination">
            <Pagination
              hasPreviousPage={allAwardsOffset === 1}
              hasNextPage={arrAwards[allAwardsOffset * 10] === undefined}
              prevPage={allAwardsPrevPage}
              nextPage={allAwardsNextPage}
            />
          </div>
        </Container>
      )}

      {/* {popup && (
        <Modal
          isOpen={modal}
          onRequestClose={handleModal}
          style={customStyles}
          contentLabel="campaign modal"
        >
          <div className="modal-popup-home modal-body">
            <div className="popup-close w-bg">
              <i className="fal fa-times" onClick={handleModal}></i>
            </div>
            <div className="container p-0">
              <div className="row">
                <div className="col-md-7 order-md-last modal-image">
                  <img src={popup.image?.sourceUrl} className="mw-100" />
                </div>
                <div className="col-md-5 order-md-first">
                  <div className="modal-content">
                    <h2>{popup.title}</h2>
                    <p>
                      <ReactMarkdown
                        source={popup.description}
                        escapeHtml={false}
                      />
                    </p>
                    <div>
                      <Button
                        variant="secondary"
                        link={popup.ctaLink}
                        target="#"
                        className="btn-block mt-4"
                      >
                        {popup.ctaLabel}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )} */}
    </Layout>
  )
}

export default PageTemplate
