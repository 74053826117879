import React, { useState } from "react"
import { nanoid } from "nanoid"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"

import { ButtonGroup, Button } from "../../anti/buttons/buttons"
import { Card } from "../../anti/card/card"
import { Section, Container } from "../../anti/grid/grid"

export const AcfTextWColCardsBlock = graphql`
  fragment AcfTextWColCardsBlock on WordPress_AcfTextWColCardsBlock {
    name
    attributes {
      className
    }
    textWColCards {
      bgColor
      title
      text
      buttons {
        text
        link {
          target
          url
        }
      }
      cardsRatio
      cards {
        img {
          sourceUrl
        }
        title
        text
        link {
          target
          url
        }
      }
    }
  }
`

export const AcfTextWColCards = ({ data }) => {
  const [id] = useState(nanoid)
  const textWCardsCol = data.textWColCards
  return (
    <Section
      className={`${textWCardsCol.bgColor} ${data.attributes.className}`}
    >
      <Container className="mw-md text-md-center">
        <h2 className="h2">
          <ReactMarkdown source={textWCardsCol.title} escapeHtml={false} />
        </h2>
        <p>
          <ReactMarkdown source={textWCardsCol.text} escapeHtml={false} />
        </p>
        {textWCardsCol.buttons && (
          <ButtonGroup>
            {textWCardsCol.buttons.map((button, i) => {
              return (
                <Button
                  variant="link"
                  link={button.link && button.link.url}
                  target={button.link && button.link.target}
                  key={`${id}${i}`}
                >
                  {button.text}
                </Button>
              )
            })}
          </ButtonGroup>
        )}
      </Container>
      <div className="row row-0">
        {textWCardsCol.cards.map((card, i) => {
          return (
            <div className="col-md-4" key={`${id}${i}`}>
              <Card
                variant="overlay"
                title={card.title}
                text={card.text}
                img={card.img && card.img.sourceUrl}
                link={card.link && card.link.url}
                target={card.link && card.link.target}
                imgHeight={textWCardsCol.cardsRatio}
              />
            </div>
          )
        })}
      </div>
    </Section>
  )
}
