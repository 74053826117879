import React, { useState, useMemo, useContext } from "react"
import { graphql } from "gatsby"
import moment from "moment"

import { Section, Container } from "../../anti/grid/grid"
import { HeadingLink } from "../../anti/type/type"
import { Button } from "../../anti/buttons/buttons"
import { CardSlider } from "../../anti/card-slider/card-slider"
import { Card } from "../../anti/card/card"
import { useQuery } from "@apollo/react-hooks"
import { LangContext } from "../../../context/LangContext"
import gql from "graphql-tag"

export const acfEventSliderBlock = graphql`
  fragment AcfEventSliderBlock on WordPress_AcfEventSliderBlock {
    attributes {
      className
    }
    eventSlider {
      bgColor
      title
      link {
        text
        url
      }
      events {
        title
        text
        img {
          sourceUrl
        }
        slug
        dateEnd
        url
      }
      news {
        title
        text
        img {
          sourceUrl
        }
        dateEnd
        url
      }
      products {
        title
        text
        img {
          sourceUrl
        }
        dateEnd
        url
      }
    }
  }
`

const queryWhatson = lang => {
  return gql`
    query getDataWhatsOn {
      news: posts(
        first: 4
        where: { categoryName: "Press Release", language: ${lang} }
      ) {
        nodes {
          title
          featuredImage {
            sourceUrl
          }
          slug
          seo {
            metaDesc
          }
        }
      }
      products: projects(first: 4, where: { language: ${lang} }) {
        nodes {
          title
          featuredImage {
            sourceUrl
          }
          slug
          propertyTypes {
            nodes {
              slug
            }
          }
          seo {
            metaDesc
          }
        }
      }
      events: posts(
        first: 4
        where: { categoryName: "Events Update", language: ${lang} }
      ) {
        nodes {
          title
          featuredImage {
            sourceUrl
          }
          slug
          seo {
            metaDesc
          }
          acfEventUpdateDateEnd {
            eventUpdateDateEnd
          }
        }
      }
    }
  `
}

export const AcfEventSlider = ({ data }) => {
  const eventSlider = data.eventSlider
  const { lang, setLang } = useContext(LangContext)
  const { data: whatsondata } = useQuery(queryWhatson(lang))

  const response = useMemo(() => {
    if (whatsondata) {
      return {
        news: whatsondata.news.nodes.map(item => {
          return {
            title: item.title,
            text: item.seo.metaDesc,
            imageUrl: item.featuredImage.sourceUrl,
            url: `${process.env.BASE_URL}/news-events/${item.slug}`,
            dateTime: null,
          }
        }),
        products: whatsondata.products.nodes.map(item => {
          const category = item.propertyTypes.nodes[0].slug
          return {
            title: item.title,
            text: item.seo.metaDesc,
            imageUrl: item.featuredImage.sourceUrl,
            url: `${process.env.BASE_URL}/development/${category}/${item.slug}`,
            dateTime: null,
          }
        }),
        events: whatsondata.events.nodes.map(item => {
          const customUrl = (eventSlider?.events || []).find(
            it => it.slug === item.slug
          )

          return {
            title: item.acfEventUpdateDateEnd.eventUpdateDateEnd,
            text: item.title,
            imageUrl: item.featuredImage.sourceUrl,
            url: customUrl
              ? customUrl.url
              : `${process.env.BASE_URL}/news-events/${item.slug}`,
            dateTime: item.acfEventUpdateDateEnd.eventUpdateDateEnd,
          }
        }),
      }
    }
    return {
      news: null,
      products: null,
      events: null,
    }
  }, [whatsondata])

  // const result = {
  //   news: hehe.news.nodes.map(item => {
  //     return {
  //       title: item.title,
  //       text: item.seo.metaDesc,
  //       imageUrl: item.featuredImage.sourceUrl,
  //       url: `${process.env.BASE_URL}/news-events/${item.slug}`,
  //       dateTime: null
  //     }
  //   }),
  //   products: hehe.products.nodes.map(item => {
  //     const category = item.propertyTypes.nodes[0].slug
  //     return {
  //       title: item.title,
  //       text: item.seo.metaDesc,
  //       imageUrl: item.featuredImage.sourceUrl,
  //       url: `${process.env.BASE_URL}/${category}/${item.slug}`,
  //       dateTime: null
  //     }
  //   }),
  //   events: hehe.events.nodes.map(item => {
  //     const category = item.propertyTypes.nodes[0].slug
  //     return {
  //       title: item.title,
  //       text: item.seo.metaDesc,
  //       imageUrl: item.featuredImage.sourceUrl,
  //       url: `${process.env.BASE_URL}/${category}/${item.slug}`,
  //       dateTime: null
  //     }
  //   })
  // }

  const tabList = [
    {
      label: "News",
      value: "news",
    },
    {
      label: "Product",
      value: "products",
    },
    {
      label: "Event",
      value: "events",
    },
  ]

  const [current, setCurrent] = useState("news")

  // const displayedData =
  //   data && data.eventSlider
  //     ? data.eventSlider[current]
  //       ? data.eventSlider[current]
  //       : []
  //     : []
  const displayedData = response[current] ? response[current] : []

  return (
    <Section className={`${eventSlider.bgColor}`}>
      <Container>
        <HeadingLink>
          <h2 className="h3">{eventSlider.title}</h2>
          <Button
            variant="link"
            link={eventSlider.link.url}
            target={eventSlider.link.target}
          >
            {eventSlider.link.text}
          </Button>
        </HeadingLink>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: 14,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: 200,
              position: "relative",
              gap: 6,
              paddingBottom: 6,
            }}
          >
            {tabList.map((item, index) => {
              return (
                <div
                  key={index}
                  style={{
                    flex: 1,
                    cursor: "pointer",
                    textAlign: "center",
                  }}
                  onClick={() => {
                    setCurrent(item.value)
                  }}
                >
                  <p
                    style={{
                      fontWeight: current === item.value ? "bold" : "normal",
                      marginBottom: 0,
                      color: "#333",
                    }}
                  >
                    {item.label}
                  </p>
                </div>
              )
            })}
            <div
              style={{
                position: "absolute",
                bottom: 0,
                left:
                  current === "news"
                    ? 0
                    : current === "products"
                    ? "33%"
                    : "68%",
                width: "33%",
                display: "flex",
                justifyContent: "center",
                transitionTimingFunction: "ease-in",
                transition: "all 0.4s",
              }}
            >
              <div className="bg-orange" style={{ width: 50, height: 2 }}></div>
            </div>
          </div>
        </div>
        <CardSlider
          showInitial={4}
          showLgDown={3}
          visibleInitial={false}
          visibleLgDown={false}
          arrowsInitial={false}
          arrowsLgDown={false}
        >
          {displayedData.length > 0 &&
            displayedData.map((item, i) => {
              let isBefore = true
              if (item.dateEnd) {
                const splitDate = item.dateEnd.split("/")
                const now = moment().format("YYYY-MM-DD")
                const formatDate = [splitDate[2], splitDate[1], splitDate[0]]
                isBefore = moment(now).isBefore(formatDate.join("-"))
              }
              return (
                <Card
                  variant="boxless"
                  title={item.title}
                  className={`${
                    isBefore ? "" : "past-event"
                  } shadow p-2 mb-5 bg-white rounded-sm text-limit-1`}
                  titleClassName="h6"
                  cardBodyClassName={"px-1 py-3"}
                  text={item.text}
                  fixedHeight={true}
                  titlePrefix={
                    current === "events" ? (
                      <i
                        className={"fas fa-calendar mr-1"}
                        style={{ color: "#333", lineHeight: "1.5" }}
                      >
                        &nbsp;
                      </i>
                    ) : null
                  }
                  img={item.imageUrl}
                  imgHeight="h-ratio-1-1"
                  link={item.url}
                  target={item.target}
                  key={i}
                />
              )
            })}
        </CardSlider>
      </Container>
    </Section>
  )
}
