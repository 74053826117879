import React from "react"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"

import { CoverContent } from "../../anti/cover/cover"
import { ButtonGroup, Button } from "../../anti/buttons/buttons"
import { Feature } from "../../anti/feature/feature"

export const acfCoverContentWIconListBlock = graphql`
  fragment AcfCoverContentWIconListBlock on WordPress_AcfColCoverContentWIconListBlock {
    attributes {
      className
    }
    coverContentWIconList {
      img {
        sourceUrl
      }
      imgOverlay
      title
      text
      buttons {
        text
        link {
          target
          url
        }
      }
      list {
        icon {
          sourceUrl
        }
        title
        description
      }
    }
  }
`

export const AcfColCoverConterWIconList = ({ data }) => {
  const block = data ? data.coverContentWIconList : false

  return (
    <CoverContent
      theme="dark"
      img={block.img.sourceUrl}
      imgOverlay={block.imgOverlay}
      contentMaxWidth="mw-md-500px mw-lg-600px"
      //   className={`${block.attributes.className}`}
    >
      <h2 className="h2">
        <ReactMarkdown source={block.title} escapeHtml={false} />
      </h2>
      <p>
        <ReactMarkdown source={block.text} escapeHtml={false} />
      </p>
      {block.buttons && (
        <ButtonGroup>
          {block.buttons.map((item, i) => {
            return (
              <Button
                variant="link"
                link={item.link && item.link.url}
                target={item.link && item.link.target}
                key={i}
              >
                {item.text}
              </Button>
            )
          })}
        </ButtonGroup>
      )}
      <div className="row mt-5">
        {block.list.map((item, i) => {
          return (
            <div className="col-md-6">
              <Feature
                img={item.icon.sourceUrl}
                title={item.title}
                text={item.description}
                key={i}
              />
            </div>
          )
        })}
      </div>
    </CoverContent>
  )
}
